.footer {
  display: flex;
  font-size: 10px;
  padding: 30px 30px;
  background-color: #eeeeee;
}

.footer * {
  margin: 0 10px;
}

.footer h2 {
  color: #666666;
}
.footer h1 {
  margin-right: 20px;
}

.footer a {
  color: white;
  background-color: #f39e40;
}
