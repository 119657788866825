@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

:root {
  --white: #f9f7f7;
  --gray: rgb(73, 80, 87);
  --lightgray: #e0e0e0;
  --blue: #3f72af;
  --xlightblue: #dbe2ef;
  --lightblue: #3aa4cf;
  --orange: #f39e40;
  --lightorange: #ebbc7a;
}

#root {
  width: 99dvw;
  min-height: 100dvh;
  min-height: -webkit-fill-available;
  max-height: 100dvh;
  display: inline-block;
}

/* TODO Add site wide styles */
body,
h1,
h2,
h3,
p,
ul {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f2a154;
}

a {
  text-decoration: none;
  font-size: 18px;
  border-radius: 3px;
  box-sizing: border-box;
  color: #f39e40;
  /*background-color: #f39e40;*/
  display: flex;
  align-items: center;
  border: 1px solid #f39e40;
  height: 34px;
}

* {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
}

button {
  box-sizing: border-box;
  height: fit-content;
  width: fit-content;
  color: white;
  background-color: var(--lightblue);
  border-radius: 3px;
  padding: 5px 10px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

button:hover {
  background-color: var(--blue);
}
a {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

li {
  list-style-type: none;
}

textarea {
  font-family: Open Sans, open-sans, EmojiFontFace, helvetica, arial, sans-serif;
  font-size: 16px;
  border: 1px solid #ccc;
  resize: none;
}

select {
  background-color: transparent;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  width: 100%;
  border: 1px solid var(black);
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}

/* get rid of arrows on number inputs */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
