.signupFormPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding-bottom: 100px;
}

/* logo*/
.logo {
  width: auto;
  height: auto;
  margin: 15px 0 30px 0;
  border: none;
}
.logo img {
  width: 200px;
}

.signupFormPage > h1 {
  margin-bottom: 30px;
  color: #444444;
  opacity: 0.5;
  font-size: 30px;
  border-top: 1px solid #444444;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}
/* form container */

.formContainer {
  background-color: white;
  width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 2px #fff, 0 0 5px #888;
  padding: 0 50px 30px 50px;
}

.signupForm {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
}

.signupForm ul {
  margin: 0;
  padding: 0;
}

.signupForm label {
  padding: 0;
  margin: 10px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #444444;
  width: 100%;
}

.signupForm input {
  height: 30px;
}

.signupForm button {
  margin: 30px;
  padding: 5px 15px;
}
.signupForm h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

/* inputs */
