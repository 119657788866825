/* minimized story stylings */
.miniStory {
  padding: 3px 5px;
  margin: 10px auto;
  background-color: var(--white);
  box-shadow: 1px 1px 3px gray;
  border-radius: 5px;
  width: 250px;
}
.miniStory__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.miniStory__details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 10px;
}
.miniStory__header p {
  padding: 10px;
  font-weight: bolder;
  font-size: 20px;
}

.miniStory button {
  padding: 0;
  background-color: transparent;
  color: #3f72af;
  margin: 0;
  margin-bottom: auto;
  height: auto;
  margin-left: auto;
}

.miniStory button:hover {
  color: #112d4e;
}

/* maximized story stylings */
.maxStory {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 10px;
  margin: 10px 7px;
  border-radius: 5px;
  max-width: 400px;
  box-shadow: 1px 1px 3px gray;
  gap: 10px;
}

.maxStory__headerButtons {
  display: flex;
  justify-content: flex-end;
}

.maxStory__minimize {
  color: var(--gray);
  background: transparent;
}

.maxStory__minimize:hover {
  background-color: transparent;
  color: var(--blue)
}

.maxStory__header {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  font-size: 12px;
}

.maxStory__status {
  margin: 0 3px;
}


.header--feature {
  margin-right: auto;
  color: orange;
}
.header--bug {
  margin-right: auto;
  color: red;
}

.maxStory textarea {
  width: 100%;
  box-sizing: border-box;
  height: 100px;
  border: 3px solid #cccccc;
  padding: 5px;
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  outline: none;
}

.maxStory__info {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.maxStory__dropdowns {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  width: 100%;
  font-size: 14px;
}

.info__status {
  width: 80%;
  margin-left: auto;
}

.info__status h3 {
  color: #495057;
}

.info__status h2 {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: black;
  font-size: 20px;
}

.maxStory__button {
  margin-bottom: 5px;
  font-size: 16px;
  padding: 5px;
  background-color: var(--lightblue)
}

.maxStory__button:hover {
  background-color: var(--blue)
}


.maxStory select {
  width: 80%;
  margin: 10px;
  font-size: 14px;
}

.comments {
  margin-top: 30px;
}

.deleteStoryButton {
  margin-top: 20px;
}