.windowContainer {
  display: flex;
  flex-direction: row;
  float: left;
  width: 100%;
  min-width: 1500px;
}

.windowContainer > li {
  margin: 5px;
}

.workspace {
  background-color: var(--white);
  width: fit-content;
  box-sizing: border-box;
}