.comment {
  margin: 10px;
  padding: 3px 6px 10px 6px;
  border-bottom: 1px solid #696969;
}

.commentHeader {
  display: flex;
  justify-content: space-between;
}

.commentHeader p {
  font-size: 0.9em;
  color: grey;
}

.comment__description {
  margin-left: 5px;
}

.comment button {
  background-color: transparent;
  color: var(--gray);
}

.comment button:hover {
  color: red
}
