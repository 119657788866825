/* Navbar stylings */
.navbar {
  background-color: #f9f7f7;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #c3d4e5;
  padding: 0px 30px 0px 30px;
  align-items: center;
  position: relative;
  max-width: 100dvw;
  gap: 5px;
}

.navbar h1 {
  margin-right: auto;
  margin-left: 0;
  color: rgb(73, 80, 87);
}

.navbar > * {
}
.navbar > a:not(.navbar__logo) {
  padding: 5px 10px;
  font-size: 18px;
  border-radius: 3px;
  box-sizing: border-box;
  color: #f39e40;
  /*background-color: #f39e40;*/
  height: fit-content;
  display: flex;
  align-items: center;
  border: 1px solid #f39e40;
  text-align: center;
}

/* logo stylings*/

.navbar__logo {
  margin-right: auto;
  padding: 0 0 5px 0;
  height: auto;
  border: none;
}

.navbar__logo img {
  height: 75px;
}

/* button stylings */

.demoButton {
  box-sizing: border-box;
  height: fit-content;
  color: white;
  border-radius: 3px;
  padding: 5px 10px;
  border: none;
  font-size: 18px;
}

/* dropdown stylings */
.profile-dropdown {
  margin-top: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}
.profile-dropdown li {
  list-style-type: none;
}
.profile-dropdown a {
  padding: 5px 10px;
  margin: 0;
}
.profile-dropdown * {
  margin: 10px 0;
}

.logout {
  margin: 0;
}

/* Profile Button stylings */
.profileContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: auto;
  position: absolute;
  top: 15px;
  align-self: flex-start;
  background-color: #f9f7f7;
  margin: auto;
}
.profileContainer__avatar {
  color: var(--orange);
  background-color: transparent;
  align-self: flex-end;
  padding: 5px;
}
.profileContainer__avatar:hover,
.profileContainer__project:hover
{
  color: darkorange;
  background-color: transparent;
}

@media screen and (max-width: 800px) {
  .navbar > a:not(.navbar__logo){
  font-size: .7em;
  padding: .4em;
  }
  .navbar > button {
    font-size: .7em;
  }

  .navbar__logo img {
  height: 60px;
  }

  .navbar > h1{
    font-size: 15px;
  }
    
}
