.projectRoot {
  max-width: 100dvw;
  /* width: fit-content; */
}

.projectPage {
  display: flex;
  min-height: 80dvh;
  background-color: var(--white);
  align-items: stretch;
  width: 100%;
  gap: 20px;
  max-width: 100dvw;
  overflow-x: scroll;
  overflow-y: hidden;
}

.sidebar {
  background-color: var(--white);
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 20px;
  box-shadow: 1px 2px 3px var(--blue);
  border: 2px solid var(--lightgray)
}

.sidebar ul {
  width: 100%;
}
.sidebar__button {
  margin: 0px 0px;
  width: 100%;
  background-color: var(--white);
  color: var(--blue);
  height: auto;
  padding: 6px 0;
  outline: none;
  border-radius: 0;
}


.sidebar__button__toggled {
  background-color: #e6eefc;
}

.sidebar button:hover {
  background-color: var(--xlightblue);
  color: var(--blue);
}
/* width */
::-webkit-scrollbar {
  width: 17px;
  width: 1dvw;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--lightgray);
  border-radius: 5px;
} 