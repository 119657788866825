body {
  background-color: #dbe2ef;
}

.dashContainer {
  padding: 30px;
  min-height: 100vh;
  max-width: 100dvw;
}

.dashContainer button {
}

/* project creation */
.projectCreator {
  margin: 30px;
}

.projectCreator form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.projectCreator input {
  height: 45px;
  font-size: 20px;
  box-sizing: border-box;
  width: 30%;
  margin: 0 15px;
}

.projectCreator button {
  height: 45px;
}

.owned__container,
.collab__container {
  display: flex;
  flex-wrap: wrap;
}

.dashContainer h1 {
  margin: 30px;
  border-bottom: 1px solid #4f575e;
  padding: 20px 0px 20px 35px;
  width: 70%;
  color: #4f575e;
}

/* Indiviual card stylings */
/* project card stylings */

.projectCard {
  border: 1px solid #dddddd;
  background-color: #f9f7f7;
  width: 500px;
  margin: 20px;
  color: #666666;
  min-height: 200px;
  display: grid;
  grid-template-rows: 60px 1fr;
}

.projectCard__header {
  border-bottom: 2px solid #efefef;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 7px;
}
.projectCard__header a {
  margin-right: auto;
  width: auto;
  height: auto;
  color: #666666;
  font-size: 24px;
  font-weight: bold;
  border: none;
}

.projectCard__header a:hover {
  color: black;
}

.projectCard__header button {
  background-color: transparent;
  color: #666666;
  height: 22px;
  width: 22px;
  margin-right: 20px;
  padding: 0;
}

.projectCard__header button:hover {
  color: black;
}

.projectCard__details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  flex: auto;
  padding: 15px;
}

.projectCard__details h2 {
  margin: 0;
  font-size: 18px;
}

.addCollabForm {
  display: flex;
  align-items: center;
}
.addCollabForm label {
  margin: 10px;
}

.addCollabForm button {
  margin: 3px;
  padding: 0;
  height: 25px;
  color: var(--gray);
  background-color: transparent;
}

.addCollabForm button:hover {
  color: var(--blue)
}

.addCollabForm input {
  width: 50px;
}

.projectEditForm {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
}

.projectEditForm > * {
  margin: 3px;
}

.projectEditForm input {
  box-sizing: border-box;
  width: 100%;
  margin-right: 30px;
  height: 100%;
  padding: 10px;
  font-size: 25px;
  font-weight: bold;
  color: darkGrey;
}

.projectEditForm button {
  background-color: transparent;
  color: #666666;
}

@media screen and (max-width: 800px) {
  .projectCard {
    max-width: 80dvw;
  }
}